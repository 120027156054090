import cx from "classnames"
import { cloneDeep } from "lodash"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { batch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import ExpandAllBox from "src/components/ExpandAllBox"
import FormField from "src/components/FormField"
import Select from "src/components/FormField/Select"
import Icon from "src/components/Icon"
import InputSearch from "src/components/InputSearch"
import Loading from "src/components/Loading"
import NoData from "src/components/NoData"
import Pill from "src/components/Pill"
import TreeRow, { clickFrom } from "src/components/TreeRow"
import rowStyles from "src/components/TreeRow/style.module.scss"
import { hardcodedAuditId } from "src/constants/audit"
import { rowBackendType, rowFrontendType } from "src/constants/rowTypes"
import { treeItemColumn } from "src/constants/treeItemColumn"
import ErrorBoundary from "src/errors/ErrorBoundary"
import { formatControlServiceCreateParams, mapSolidity } from "src/helpers/control"
import { debounce } from "src/helpers/debounce"
import { mapActionPlan, mapImpact2, mapModelStatus } from "src/helpers/find"
import { getToggleState } from "src/helpers/getToggleState"
import { mapWorksheetsModelStatus } from "src/helpers/map-statuses"
import { matchsSearchText } from "src/helpers/search"
import { mapExecutionValidation } from "src/helpers/test"
import {
  countChildrenBy,
  findFieldFromRanges,
  findFromConfigList,
  getParamQueryByTypeTree,
  getTreeitemConfig,
  getTreeitemLinkUrl,
  getTreeitemType
} from "src/helpers/tree"
import { getFrontendType } from "src/helpers/typeFormatter"
import useAccessControl from "src/hooks/useAccessControl"
import { useAuditAccessControl } from "src/hooks/useAuditAccessControl"
import useQueryString from "src/hooks/useQueryString"
import parse, { sortTreeData } from "src/macrocomponents/Tree/Parser/TreeDataParser"
import { getGenericSetting, getInherentRiskSettings } from "src/services/accountSettings.service"
import { createFromTemplate } from "src/services/risk.service"
import {
  copyTreeItem,
  createTreeItem,
  createTreeItemFromCopy,
  deleteTreeItem,
  getTreeData,
  putTreeitemGenericAction,
  updateTreeItem
} from "src/services/tree.service"
import {
  createTreeEvaluationItem,
  deleteTreeEvaluationItem,
  updateTreeEvaluationItem
} from "src/services/treeEvaluation.service"
import { updateTree } from "src/store/tree/actions"
import {
  calculateNextItemPosition,
  findInTreeBy,
  findParents,
  findSiblings,
  getRowIcon,
  getRowLabel,
  rowHasPositionProp,
  treeRowSort
} from "src/store/tree/helpers"
import useDispatch from "../../store"
import * as sidenavContainerActions from "../../store/sidenavContainer/actions"
import * as treeActions from "../../store/tree/actions"
import CreateControl from "../CreateControl"
import CreateRisk from "../CreateRisk"
import { useAdvancedFiltersAudit } from "./AdvancedFilters"
import CreateTreeItemAction from "./CreateTreeItemAction"
import DeleteTreeItemAction from "./DeleteTreeItemAction"
import DuplicateTreeItemAction from "./DuplicateTreeItemAction"
import FilterWarning from "./FilterWarning"
import RenameTreeItemAction from "./RenameTreeItemAction"
import TreeitemNewWizardDialog from "./TreeitemNewWizardDialog"
import styles from "./style.module.scss"
import { getExecutionAverage, getProgress } from "./utils"

export function rowLabelIconColor(itemType, statuses, item, treeConfigs = {}) {
  const defaultColor = "#424242"

  const getStatusData = (found) => {
    if (typeof found?.data === "function") {
      return found.data(item)
    }

    return found?.data
  }

  if (typeof statuses !== "undefined") {
    switch (itemType) {
      case rowFrontendType.BUSINESS_UNIT:
        return "#0A858D"
      case rowFrontendType.UNDERSTANDING:
        return "#5F6368"
      case rowFrontendType.MACROPROCESS:
        return "#0A858D"
      case rowFrontendType.PROCESS:
        return "#0A858D"
      case rowFrontendType.RISK: {
        const found = statuses.find((el) => el.statusId === treeItemColumn.INHERENT_RISK)
        return findFromConfigList(treeConfigs?.riesgo_ri_ranges, getStatusData(found)?.value, "color", defaultColor)
      }
      case rowFrontendType.CONTROL: {
        const found = statuses.find((el) => el.statusId === treeItemColumn.SOLIDITY)
        const solidity = mapSolidity(getStatusData(found))
        return solidity === null || typeof solidity === "undefined" ? defaultColor : solidity.color
      }
      case rowFrontendType.TEST: {
        const found = statuses.find((el) => el.statusId === treeItemColumn.EXECUTION)
        const execution = mapExecutionValidation(getStatusData(found)?.value)
        return execution === null || typeof execution === "undefined" ? defaultColor : execution.color
      }
      case rowFrontendType.FINDING: {
        const status = mapImpact2(
          treeConfigs?.hallazgo_impacto_ranges === undefined
            ? null
            : treeConfigs?.hallazgo_impacto_ranges.filter((x) => x.is_active == 1),
          item.impacto
        )
        return status === null || typeof status === "undefined" ? defaultColor : status.color
      }
      case rowFrontendType.ACTION_PLAN: {
        const found = statuses.find((el) => el.statusId === "ACTION_PLAN")
        const status = mapActionPlan(getStatusData(found)?.status)

        return status === null || typeof status === "undefined" ? defaultColor : status.color
      }

      case rowFrontendType.EVALUATION: {
        const found = statuses.find((el) => el.statusId === "EVALUATION_STATE")
        const status = mapModelStatus(getStatusData(found)?.status)

        return status === null || typeof status === "undefined" ? defaultColor : status.color
      }
      case rowFrontendType.STEP: {
        const found = statuses.find((el) => el.statusId === treeItemColumn.STATUS)
        const status = mapWorksheetsModelStatus(getStatusData(found)?.status)
        return status === null || typeof status === "undefined" ? defaultColor : status.color
      }
      case rowFrontendType.INSPECTION: {
        return defaultColor
      }
      case rowFrontendType.SURVEY: {
        const found = statuses.find((el) => el.statusId === treeItemColumn.STATUS)
        const status = mapWorksheetsModelStatus(getStatusData(found)?.status)
        return status === null || typeof status === "undefined" ? defaultColor : status.color
      }
      default:
        return defaultColor
    }
  } else {
    return defaultColor
  }
}

export const TreeConfigContext = createContext({})
TreeConfigContext.displayName = "TreeConfigContext"

const TreeRowContext = createContext()

export default function Tree(props) {
  const {
    className = null,
    auditName,
    auditId,
    typeTree = "auditPlan",
    worksheetId = "",

    yearTree,
    readOnly = false,
    sprints = { enabled: false, items: [] },
    ...otherProps
  } = props

  const router = useRouter()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const {
    Button: AdvancedFiltersButton,
    Panel: AdvancedFiltersPanel,
    treeState,
    values: filtersApplied,
    removeFilters
  } = useAdvancedFiltersAudit(typeTree)

  const {
    sidenavContainer: { worksheetInFront, worksheets, openWorksheetEventually }
  } = useSelector((state) => state)
  const currentEmpresaId = useSelector((state) => state.project?.empresa?.id)
  const projectData = useSelector((state) => state.project)

  const [viewMode, setViewMode] = useState("tree")

  const [selectedIndexes, setSelectedIndexes] = useState([])

  const [hasLoaded, setHasLoaded] = useState(false)

  const [searchText, setSearchText] = useState("")

  const [newItemId, setNewItemId] = useState("")
  const [riSettings, setRiSettings] = useState(null)

  const createObjectOptionRef = useRef(null)
  const moveUpObjectOptionRef = useRef(null)
  const moveDownObjectOptionRef = useRef(null)

  const itemDraggingRef = useRef(null)

  const { hasPermission } = useAccessControl()
  const { hasAuditMutablePermission, buildAuditPermissionFlags } = useAuditAccessControl()

  const hasPermissionOnEditTree = (perm, item) => {
    if (typeTree !== "auditPlan") {
      return hasPermission("pyr_evaluacion.edit_tree")
    }

    if (!item) {
      return false
    }

    if (item.item_tipo.substring(0, 2) == "GR") {
      return hasAuditMutablePermission(`plan.treeitem__${perm}`, null, item)
    }

    return hasAuditMutablePermission(
      `plan.${item.type}__${perm}`,
      buildAuditPermissionFlags(item, ["assigned_user", "not_reviewed"]),
      item
    )
  }

  const hasPermissionOnCreateTree = (item_tipo) => {
    if (typeTree !== "auditPlan") {
      return hasPermission("pyr_evaluacion.edit_tree")
    }

    if (item_tipo.substring(0, 2) == "GR") {
      return hasAuditMutablePermission(`plan.treeitem__add`)
    }

    return hasAuditMutablePermission(`plan.${getFrontendType(item_tipo)}__add`)
  }

  const setOpenWorksheetEventually = (value) => {
    dispatch({ type: "OPEN_WORKSHEET_EVENTUALLY", value })
  }

  const onSearch = useCallback(
    debounce((text) => {
      setSearchText(text)
      dispatch(treeActions.filterTree({ searchText: text }))
    }, 500),
    []
  )

  useEffect(() => {
    if (!currentEmpresaId) {
      return
    }

    getInherentRiskSettings(currentEmpresaId).then(({ data }) => {
      setRiSettings(data.valor)
    })
  }, [currentEmpresaId])

  useEffect(() => {
    if (typeof openWorksheetEventually !== "string") {
      return
    }

    if (openWorksheetEventually === "") {
      return
    }

    const [worksheetType = "", worksheetId = ""] = openWorksheetEventually.split(",")

    if (worksheetType === "" || worksheetId === "") {
      setOpenWorksheetEventually("")
      return
    }

    const [entry, entryIndexes] = findInTreeBy(treeState.rows, (r) => r.id === worksheetId)

    if (entry === null) {
      return
    }

    batch(() => {
      dispatch({
        type: "TREE.ACTIVATE_ROW",
        indexes: entryIndexes,
        prevIndexes: [...selectedIndexes]
      })

      dispatch(
        sidenavContainerActions.openWorksheetAsync({
          data: entry,
          indexes: entryIndexes,
          key: worksheetType,
          auditId,
          typeTree
        })
      )
    })

    setOpenWorksheetEventually("")
  }, [openWorksheetEventually, treeState.rows, selectedIndexes])

  const onActionParameterUpdate = (action, actionData) => {
    switch (action) {
      case "create-flowchart": {
        const { targetId, diagramURL } = actionData

        const [item, indexes] = findInTreeBy(treeState.rows, (row) => row["id"] === targetId)

        dispatch(
          sidenavContainerActions.openWorksheetAsync({
            data: { ...item, diagramURL },
            indexes,
            key: "flowchart",
            auditId,
            typeTree
          })
        )

        updateQueryParameter("_clear")

        break
      }
    }
  }

  const onQueryStringUpdate = (value) => {
    if ("action" in value && "target-id" in value && "diagram-url" in value) {
      onActionParameterUpdate(value.action, {
        targetId: value["target-id"],
        diagramURL: value["diagram-url"]
      })
    }
  }

  const updateQueryParameter = useQueryString(
    ["target-id", "action", "diagram-url"],
    onQueryStringUpdate,
    {},
    true,
    true
  )

  const closeAddWizardTreeitem = () => dispatch({ type: "TREE.CLOSE_MODAL" })

  const makeRowOptions = (_item, indexes) => {
    const options = []
    if (_item.item_tipo === rowBackendType.HT_CONTROL && typeTree === "riskEvaluation") {
      return []
    }

    const createHandler = (cb) => {
      return (...args) => {
        setSelectedIndexes(Array.from(indexes))

        cb(...args)
      }
    }

    const openAddWizardTreeitem = createHandler((item_tipo, config = {}) => {
      dispatch({ type: "TREE.OPEN_MODAL", value: "TREEITEM_NEW", params: { ...config, item_tipo, parent: _item } })
    })

    const createObjectOption = {
      ref: createObjectOptionRef,
      component: CreateTreeItemAction,
      icon: {
        name: "add_black",
        color: "#444444"
      },
      options: []
    }

    const moveObjectFn = (up = true) => {
      const siblings = findSiblings(indexes, treeState.rows)

      const rowsToUpdate = siblings.reduce(
        (agg, value, valueIdx) => {
          const _rowHasPositionProp = rowHasPositionProp(value)
          const isCurrentRow = value.id === _item.id

          if (_rowHasPositionProp) {
            agg.withPosition.push({
              ...value,
              ogPosition: value.position,
              isCurrentRow,
              localIdx: valueIdx
            })
          } else {
            agg.withoutPosition.push({
              ...value,
              isCurrentRow,
              localIdx: valueIdx
            })
          }

          return agg
        },
        {
          withPosition: [],
          withoutPosition: []
        }
      )

      rowsToUpdate.withPosition = rowsToUpdate.withPosition.sort(treeRowSort).map((rowToUpdate, rowsToUpdateIdx) => {
        rowToUpdate.position = rowsToUpdateIdx + 1

        return rowToUpdate
      })

      const lastWithPositionIdx =
        rowsToUpdate.withPosition.length > 0
          ? rowsToUpdate.withPosition[rowsToUpdate.withPosition.length - 1].position
          : 0

      rowsToUpdate.withoutPosition = rowsToUpdate.withoutPosition.map((rowToUpdate, rowsToUpdateIdx) => {
        rowToUpdate.position = lastWithPositionIdx + rowsToUpdateIdx + 1

        return rowToUpdate
      })

      const _rowsToUpdate = [...rowsToUpdate.withPosition, ...rowsToUpdate.withoutPosition]

      const _currentRowIdx = _rowsToUpdate.findIndex((rowToUpdate) => rowToUpdate.isCurrentRow)
      const _currentRow = _rowsToUpdate[_currentRowIdx]

      if (up && _currentRowIdx > 0) {
        _rowsToUpdate[_currentRowIdx - 1].position = _currentRow.position
        _currentRow.position = _currentRow.position - 1
      } else if (!up && _currentRowIdx < _rowsToUpdate.length - 1) {
        _rowsToUpdate[_currentRowIdx + 1].position = _currentRow.position
        _currentRow.position = _currentRow.position + 1
      }

      const updatePromises = _rowsToUpdate
        .filter((rowToUpdate) => !("ogPosition" in rowToUpdate && rowToUpdate.ogPosition === rowToUpdate.position))
        .map((rowToUpdate) => {
          switch (typeTree) {
            case "auditPlan":
              return updateTreeItem({
                auditId,
                itemId: rowToUpdate.id,
                dataToUpdate: {
                  position: rowToUpdate.position
                }
              }).then((result) => {
                return {
                  ...result.data,
                  localIdx: rowToUpdate.localIdx
                }
              })
            case "riskEvaluation":
              return updateTreeEvaluationItem({
                evaluationId: auditId,
                itemId: rowToUpdate.id,
                dataToUpdate: {
                  position: rowToUpdate.position
                }
              }).then((result) => {
                return {
                  ...result.data,
                  localIdx: rowToUpdate.localIdx
                }
              })
            default:
              break
          }
        })
      Promise.all(updatePromises)
        .then((rowsData) => {
          batch(() => {
            rowsData.forEach((rowData) => {
              dispatch(
                updateTree({
                  indexes: [...indexes.slice(0, indexes.length - 1), rowData.localIdx],
                  data: {
                    position: rowData["position"]
                  }
                })
              )
            })
          })
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response?.data?.message || error.message))
        })
    }

    let disabled =
      "position" in _item
        ? typeof _item.position === "number" && _item.position > 1
          ? false
          : !(typeof _item.position !== "number" && indexes[indexes.length - 1] > 0)
        : true

    const moveUpObjectOption = {
      tooltip: disabled ? false : "Mover arriba",
      ref: moveUpObjectOptionRef,
      icon: {
        name: "keyboard_arrow_up",
        color: "#444444"
      },
      disabled: disabled,
      showTooltip: !disabled,
      options: [],
      action: () => {
        moveObjectFn(true)
      }
    }

    const siblings = findSiblings(indexes, treeState.rows)

    disabled =
      "position" in _item
        ? typeof _item.position === "number" && _item.position < siblings.length
          ? false
          : !(typeof _item.position !== "number" && indexes[indexes.length - 1] < siblings.length - 1)
        : true

    const moveDownObjectOption = {
      tooltip: disabled ? false : "Mover abajo",
      ref: moveDownObjectOptionRef,
      icon: {
        name: "keyboard_arrow_down",
        color: "#444444"
      },
      disabled: disabled,
      showTooltip: !disabled,
      options: [],
      action: () => {
        moveObjectFn(false)
      }
    }

    const renameObjectOption = (item) => {
      return { component: <RenameTreeItemAction key={`rename-${item.id}`} item={item} onSubmit={handleRenameObject} /> }
    }

    const deleteObjectOption = (item) => {
      return { component: <DeleteTreeItemAction key={`delete-${item.id}`} item={item} onDelete={handleDeleteObject} /> }
    }

    const duplicateObjectOption = (item) => {
      return {
        component: (
          <DuplicateTreeItemAction
            key={`duplicate-${item.id}`}
            item={item}
            indexes={indexes}
            onSubmit={handleDuplicateObject}
          />
        )
      }
    }

    const addMenuitemCreateOption = (items) => {
      if (!Array.isArray(items)) {
        items = [items]
      }
      items.forEach((item_tipo) => {
        if (hasPermissionOnCreateTree(item_tipo)) {
          createObjectOption.options.push({
            item_tipo,
            action: () => {
              openAddWizardTreeitem(item_tipo, getNewTreeitemModalConfigs(item_tipo, _item, indexes))
            }
          })
        }
      })
    }

    if (
      hasPermissionOnEditTree("edit", _item) &&
      getTreeitemConfig(_item, "allowSort", true) &&
      _item.type !== rowFrontendType.ACTION_PLAN &&
      _item.type !== rowFrontendType.EVALUATION
    ) {
      options.push(moveUpObjectOption, moveDownObjectOption)
    }

    if (
      (_item.type !== rowFrontendType.ACTION_PLAN &&
        _item.type !== rowFrontendType.EVALUATION &&
        _item.type !== rowFrontendType.FINDING &&
        _item.type !== rowFrontendType.LINK &&
        _item.type !== rowFrontendType.SURVEY &&
        typeTree === "auditPlan") ||
      (_item.type !== rowFrontendType.RISK &&
        _item.type !== rowFrontendType.UNDERSTANDING &&
        typeTree === "riskEvaluation")
    ) {
      options.push(createObjectOption)
    }

    switch (_item.type) {
      case rowFrontendType.MACROPROCESS:
      case rowFrontendType.PROCESS:
      case rowFrontendType.SUB_PROCESS: {
        addMenuitemCreateOption(["HT_ENTENDIMIENTO"])
        break
      }
    }

    switch (_item.type) {
      case rowFrontendType.PHASE:
        addMenuitemCreateOption(["GR_MACROPROCESO", "GR_PROCESO"])
        break
      case rowFrontendType.MACROPROCESS:
        addMenuitemCreateOption(["GR_PROCESO"])
        break
      case rowFrontendType.PROCESS:
        addMenuitemCreateOption(["GR_ACTIVIDAD"])
        break
      case rowFrontendType.BUSINESS_UNIT:
        addMenuitemCreateOption(["GR_MACROPROCESO", "GR_PROCESO"])
        break
    }

    switch (_item.type) {
      case rowFrontendType.PHASE:
      case rowFrontendType.MACROPROCESS:
      case rowFrontendType.PROCESS:
      case rowFrontendType.SUB_PROCESS:
        addMenuitemCreateOption(["HT_RIESGO"])
    }

    if (_item.type === rowFrontendType.BUSINESS_UNIT && typeTree === "auditPlan") {
      addMenuitemCreateOption(["HT_RIESGO"])
    }

    if (_item.type === rowFrontendType.RISK && typeTree === "auditPlan") {
      addMenuitemCreateOption(["HT_CONTROL"])
    }

    if (_item.type === rowFrontendType.CONTROL) {
      addMenuitemCreateOption(["HT_CONTROL_TEST"])
    }

    if (
      _item.type !== rowFrontendType.FINDING &&
      _item.type !== rowFrontendType.LINK &&
      _item.type !== rowFrontendType.SURVEY &&
      typeTree === "auditPlan"
    ) {
      addMenuitemCreateOption(["HT_HALLAZGO"])
    }

    if (_item.type === rowFrontendType.FINDING && typeTree === "auditPlan") {
      addMenuitemCreateOption(["HT_HALLAZGO"])
    }

    if (
      _item.type !== rowFrontendType.FINDING &&
      _item.type !== rowFrontendType.TEST &&
      _item.type !== rowFrontendType.STEP &&
      _item.type !== rowFrontendType.LINK &&
      _item.type !== rowFrontendType.CONTROL &&
      _item.type !== rowFrontendType.INSPECTION &&
      _item.type !== rowFrontendType.UNDERSTANDING &&
      _item.type !== rowFrontendType.SURVEY &&
      typeTree === "auditPlan"
    ) {
      addMenuitemCreateOption(["HT_PASO"])
    }

    if (_item.type === rowFrontendType.INSPECTION) {
      addMenuitemCreateOption(["HT_INSPECCION_ITEM", "HT_RIESGO"])
    }

    if (_item.type === "step") {
      addMenuitemCreateOption(["HT_RIESGO"])
    }

    if (getTreeitemConfig(_item, "allowRename", true) && hasPermissionOnEditTree("edit", _item)) {
      options.push(renameObjectOption(_item))
    }

    if (getTreeitemConfig(_item, "allowDuplicate", false) && hasPermissionOnEditTree("edit", _item)) {
      options.push(duplicateObjectOption(_item))
    }

    if (getTreeitemConfig(_item, "allowDelete", true) && hasPermissionOnEditTree("delete", _item)) {
      options.push(deleteObjectOption(_item))
    }

    return options
  }

  const makeOnDragStart = ({ indexes, item }) => {
    return (event) => {
      event.stopPropagation()
      const _data = event.dataTransfer.getData("text")
      const _elem = event.target

      if ((_data !== null && _data !== "") || _elem === null || !_elem.classList.contains(rowStyles.treeRow)) {
        return
      }

      itemDraggingRef.current = item

      event.dataTransfer.setData("text/plain", indexes.join(","))
    }
  }

  const makeOnDragEnd = () => {
    return () => {
      itemDraggingRef.current = null
    }
  }

  const makeOnDrop = ({ indexes, itemId: parentId }) => {
    return (event) => {
      const transferData = event.dataTransfer.getData("text")

      if (
        typeof transferData === "undefined" ||
        transferData === null ||
        transferData === "" ||
        itemDraggingRef?.current === null
      ) {
        return
      }

      const fromIndexes = event.dataTransfer
        .getData("text")
        .split(",")
        .map((x) => parseInt(x, 10))

      event.dataTransfer.clearData()

      if (fromIndexes.length === 0) {
        return
      }

      let _updatePromise = null

      switch (typeTree) {
        case "auditPlan":
          _updatePromise = putTreeitemGenericAction(itemDraggingRef.current.id, "change_parent", { parent: parentId })

          dispatch({ type: "PAGE_LOADING", value: true })
          _updatePromise?.then(({ data }) => {
            dispatch({
              type: "UPDATE_TREE_ROW",
              indexes: [...fromIndexes],
              data: { position: data.record.position },
              overwrite: true
            })
            dispatch({
              type: "TREE.MOVE_ROW",
              prevIndexes: [...fromIndexes],
              indexes: [...indexes]
            })
            dispatch({ type: "PAGE_LOADING", value: false })

            itemDraggingRef.current = null
          })

          break
        case "riskEvaluation":
          _updatePromise = updateTreeEvaluationItem({
            evaluationId: auditId,
            itemId: itemDraggingRef.current.id,
            dataToUpdate: {
              parent: parentId
            }
          })

          dispatch({ type: "PAGE_LOADING", value: true })
          _updatePromise?.then(() => {
            dispatch({
              type: "TREE.MOVE_ROW",
              prevIndexes: [...fromIndexes],
              indexes: [...indexes]
            })
            dispatch({ type: "PAGE_LOADING", value: false })

            itemDraggingRef.current = null
          })

          break
      }
    }
  }

  const handleDefaultCreateSave = (event, values, configs = {}) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    setNewItemId("")

    dispatch({ type: "PAGE_LOADING", value: t("main_ui.general.lb_creating_record") })

    const [parentRow, indexes] = findInTreeBy(treeState.rows, (item) => item.id == values.parentId)

    const position =
      typeof values.position == "undefined"
        ? parentRow == null
          ? treeState.rows.length + 1
          : calculateNextItemPosition(parentRow)
        : values.position

    let createPromise

    switch (typeTree) {
      case "auditPlan": {
        if (values.create_type == "new") {
          const createData = {
            auditId,
            item_tipo: values.item_tipo,
            name: values.value,
            parent: values.parentId,
            position
          }
          if (values.item_tipo == "HT_HALLAZGO" && values.ref_parent) {
            createData.ref_parent = values.ref_parent
          }
          createPromise = createTreeItem(createData)
        } else {
          const createData = {
            ids: values.value.value,
            copy_from: values.value.copy_from,
            parent: values.parentId,
            copy_options: configs?.copy_options ?? {}
          }
          if (values.ref_parent) {
            createData.copy_options.ref_parent = values.ref_parent
          }
          createPromise = copyTreeItem("auditoria", auditId, createData)
        }
        break
      }
      case "riskEvaluation": {
        if (values.create_type == "new") {
          createPromise = createTreeEvaluationItem({
            evaluationId: auditId,
            item_tipo: values.item_tipo,
            name: values.value,
            parent: values.parentId,
            position
          })
        } else {
          createPromise = copyTreeItem("evaluacion", auditId, {
            ids: values.value.value,
            copy_from: values.value.copy_from,
            parent: values.parentId,
            copy_options: configs?.copy_options ?? {}
          })
        }
        break
      }
      default:
        return
    }

    return createPromise
      .then(({ data }) => {
        return configs?.beforeAddTotree ? configs?.beforeAddTotree(data) : data
      })
      .then((data, from) => {
        if (values.create_type == "new") {
          dispatch(treeActions.treeAdd({ indexes: [...indexes], data, typeTree, include_items: true }))

          setNewItemId(data.id)
          if (values.item_tipo.substring(0, 3) == "HT_") {
            const item_type = getTreeitemConfig(values.item_tipo, "typeName")
            setOpenWorksheetEventually(`${item_type},${data.id}`)
          }
        } else {
          const resultEntries = Array.isArray(data) ? data : [data]

          const [parentRow, indexes] = findInTreeBy(treeState.rows, (item) => item.id == configs.parent?.id)

          let lastEntry = resultEntries[resultEntries.length - 1] ?? null

          batch(() => {
            resultEntries.forEach((result) => {
              const entry = "data" in result ? result.data : result
              dispatch(treeActions.treeAdd({ indexes, data: entry, from, typeTree, include_items: true }))
            })
          })

          if (lastEntry === null) {
            return
          }

          if (values.item_tipo.substring(0, 3) == "HT_") {
            const item_type = getTreeitemConfig(values.item_tipo, "typeName")
            lastEntry = "data" in lastEntry ? lastEntry.data : lastEntry
            setOpenWorksheetEventually(`${item_type},${lastEntry.id}`)
          }
        }
      })
      .finally(() => {
        dispatch({ type: "PAGE_LOADING", value: false })
      })
  }

  const handleCreateStep = (createValues) => {
    if (hasLoaded) {
      return
    }

    setHasLoaded(true)
    dispatch({ type: "PAGE_LOADING", value: "Creando..." })

    const [targetRow, _indexes] = findInTreeBy(treeState.rows, (item) => item.id == createValues.parentId)
    const position = calculateNextItemPosition(targetRow)

    if (createValues.create_type == "new") {
      const paso_tipo =
        createValues.tipo === "inspeccion" && createValues.info.t != "1"
          ? "INSPECCION_PASO"
          : createValues.tipo.toUpperCase()

      createTreeItem({
        auditId,
        item_tipo: createValues.tipo === "inspeccion" ? rowBackendType.HT_INSPECTION : rowBackendType.HT_STEP,
        paso_tipo,
        nombre: createValues.info.name,
        frequency: createValues.info.frequency,
        frequency_other: createValues.info.frequency_other,
        parent: createValues.parentId,
        position
      })
        .then((data) => {
          dispatch(
            treeActions.treeAdd({
              indexes: [...createValues.indexes],
              data: data.data,
              typeTree,
              include_items: true
            })
          )

          setNewItemId(data.data.id)

          closeAddWizardTreeitem()

          if (createValues.autoOpenCreated) {
            setOpenWorksheetEventually(`${createValues.tipo === "inspeccion" ? "inspection" : "step"},${data.data.id}`)
          }
        })
        .finally(() => {
          setHasLoaded(false)
          dispatch({ type: "PAGE_LOADING", value: false })
        })
    } else {
      createTreeItemFromCopy({
        service: "pasos",
        auditId,
        parentId: createValues.parentId,
        ids: createValues.ids,
        copy_options: createValues.copyOptions
      })
        .then(({ data }) => {
          const resultEntries = Array.isArray(data) ? data : [data]
          const indexes = [...createValues.indexes]
          let lastEntry = resultEntries[resultEntries.length - 1] ?? null

          batch(() => {
            resultEntries.forEach((result) => {
              const entry = "data" in result ? result.data : result

              dispatch(
                treeActions.treeAdd({
                  indexes,
                  data: entry,
                  typeTree,
                  include_items: true
                })
              )
            })
          })

          if (lastEntry === null) {
            return
          }

          lastEntry = "data" in lastEntry ? lastEntry.data : lastEntry

          setOpenWorksheetEventually(
            `${lastEntry.item_tipo == "HT_INSPECCION" ? "inspection" : "step"},${lastEntry.id}`
          )

          closeAddWizardTreeitem()
        })
        .finally(() => {
          setHasLoaded(false)
          dispatch({ type: "PAGE_LOADING", value: false })
        })
    }
  }

  const handleDeleteObject = (item) => {
    let promiseDelete
    switch (typeTree) {
      case "auditPlan":
        promiseDelete = deleteTreeItem({
          auditId,
          itemId: item.id
        })
        break
      case "riskEvaluation":
        promiseDelete = deleteTreeEvaluationItem({
          evaluationId: auditId,
          itemId: item.id
        })
        break

      default:
        break
    }

    dispatch({ type: "PAGE_LOADING", value: t("main_ui.general.lb_deleting_record") })
    return promiseDelete.then(() => {
      const [, _indexes] = findInTreeBy(treeState.rows, (row) => row.id === item.id)

      dispatch({ type: "TREE.DELETE_OBJECT", indexes: _indexes })

      dispatch(sidenavContainerActions.closeWorksheet({ key: "finding" }))
      dispatch({ type: "PAGE_LOADING", value: false })
      toast.success(t("main_ui.general.lb_record_deleted"))
    })
  }

  const handleRenameObject = (item, newName) => {
    let promise
    switch (typeTree) {
      case "auditPlan":
        promise = updateTreeItem({ auditId, itemId: item.id, dataToUpdate: { name: newName } })
        break

      case "riskEvaluation":
        promise = updateTreeEvaluationItem({ evaluationId: auditId, itemId: item.id, dataToUpdate: { name: newName } })
        break
    }

    return promise.then(() => {
      const [, _indexes] = findInTreeBy(treeState.rows, (row) => row.id === item.id)
      dispatch({ type: "TREE.RENAME_OBJECT", indexes: _indexes, name: newName })
    })
  }

  const beforeAddTotreeHallazgoCallback = (data) => {
    return new Promise((resolve) => {
      const fetchTreeData = async () => {
        const { data } = await getTreeData(auditId)
        const parsedData = await parse(data.tree, 0, data.configs)

        dispatch({
          type: "TREE.POPULATE",
          data: parsedData,
          configs: treeState.configs,
          persistPosition: getToggleState(auditId),
          modifiedTreeConfig: true
        })
      }

      fetchTreeData()
      resolve(data)
    })
  }

  const handleDuplicateObject = (item, itemIndexes, duplicateValues) => {
    const copy_from = typeTree === "auditPlan" ? "auditoria" : "evaluacion"
    const configs = {}
    const extra_values = {}

    const copy_options = {}
    switch (item.item_tipo) {
      case "HT_ENTENDIMIENTO":
        copy_options.entendimiento_flujograma = true
        break
      case "HT_HALLAZGO":
        configs.beforeAddTotree = beforeAddTotreeHallazgoCallback

        let ref_parent_id = null
        const parents = findParents(itemIndexes, treeState.rows)
        const htref = parents ? parents?.find((x) => x && x.item_tipo == "HT_REF") : null
        if (htref) {
          ref_parent_id = htref.parentId
        }
        if (ref_parent_id) {
          extra_values.ref_parent = ref_parent_id
        }

        break
    }

    handleDefaultCreateSave(
      null,
      {
        parentId: item.parentId,
        item_tipo: item.item_tipo,
        create_type: "copy",
        value: { value: [item.id], copy_from },
        ...extra_values
      },
      {
        ...configs,
        copy_options: { ...copy_options, ...duplicateValues, force_copy: 1 },
        parent: item.parentId ? { id: item.parentId } : null
      }
    )
  }

  const getNewTreeitemModalConfigs = (item_tipo, item, itemIndexes) => {
    const configs = {}

    if (item_tipo.substring(0, 3) == "GR_") {
      configs.onSave = handleDefaultCreateSave
    }

    switch (item_tipo) {
      case "HT_RIESGO":
        configs.component = (configs) => (
          <CreateRisk
            open
            onClose={closeAddWizardTreeitem}
            onCreate={(data, from) => {
              const resultEntries = Array.isArray(data) ? data : [data]

              const [parentRow, indexes] = findInTreeBy(treeState.rows, (item) => item.id == configs.parent?.id)

              let lastEntry = resultEntries[resultEntries.length - 1] ?? null

              batch(() => {
                resultEntries.forEach((result) => {
                  const entry = "data" in result ? result.data : result
                  dispatch(treeActions.treeAdd({ indexes, data: entry, from, typeTree, include_items: true }))
                })
              })

              if (lastEntry === null) {
                return
              }

              lastEntry = "data" in lastEntry ? lastEntry.data : lastEntry

              setOpenWorksheetEventually(`risk,${lastEntry.id}`)

              closeAddWizardTreeitem()
            }}
            parentId={configs.parent?.id}
            auditId={auditId}
            typeTree={typeTree}
          />
        )
        break
      case "HT_CONTROL":
        configs.component = (configs) => (
          <CreateControl
            open
            onClose={closeAddWizardTreeitem}
            onCreate={({ data }) => {
              const resultEntries = Array.isArray(data) ? data : [data]
              const [parentRow, indexes] = findInTreeBy(treeState.rows, (item) => item.id == configs.parent?.id)

              let lastEntry = resultEntries[resultEntries.length - 1] ?? null

              batch(() => {
                resultEntries.forEach((result) => {
                  const entry = "data" in result ? result.data : result

                  dispatch(treeActions.treeAdd({ indexes, data: entry, typeTree, include_items: true }))
                })
              })

              if (lastEntry === null) {
                return
              }

              lastEntry = "data" in lastEntry ? lastEntry.data : lastEntry

              setOpenWorksheetEventually(`control,${lastEntry.id}`)
              setNewItemId(lastEntry.id)

              closeAddWizardTreeitem()
            }}
            createService={(params) =>
              createTreeItem(formatControlServiceCreateParams(auditId, configs.parent?.id, params))
            }
            auditId={auditId}
            parentId={configs.parent?.id}
          />
        )
        break
      case "HT_ENTENDIMIENTO":
        configs.initialValues = { name: `Entendimiento de ${item.nombre}`, position: 0 }
        configs.copy_options = {
          entendimiento_flujograma: true
        }
        break

      case "HT_HALLAZGO":
        configs.beforeAddTotree = beforeAddTotreeHallazgoCallback
        configs.initialValues = {}

        let ref_parent_id = null
        const htref = []
          .concat([item], item.parents ? item.parents : [])
          ?.find((x) => x && ["HT_REF", "HT_CONTROL"].includes(x.item_tipo))

        if (htref) {
          ref_parent_id = htref.parentId
        }
        if (ref_parent_id) {
          configs.initialValues.ref_parent = ref_parent_id
        }

        break
      case "HT_PASO":
      case "HT_INSPECCION_ITEM":
        configs.component = (configs) => {
          const CreateStepWizard = dynamic(() => import("src/macrocomponents/AuditTree/CreateStepWizard"), {
            loading: () => <Loading text='Cargando componente...' curtain={false} />
          })

          return item?.item_tipo === "HT_INSPECCION" ? (
            <CreateStepWizard
              isOpen
              initialStep={2}
              defaultTipo={"inspeccion_item"}
              parentId={configs.parent?.id}
              onSubmit={handleCreateStep}
              onClose={closeAddWizardTreeitem}
            />
          ) : (
            <CreateStepWizard
              isOpen
              title='$t(frontend.auditoria.treeitem.paso)'
              parentId={configs.parent?.id}
              onSubmit={handleCreateStep}
              onClose={closeAddWizardTreeitem}
            />
          )
        }
        break
      default:
        break
    }

    if (!configs.onSave && !configs.component) {
      configs.onSave = handleDefaultCreateSave
    }

    return configs
  }

  const renderDialogs = () => {
    if (treeState.openModal && treeState.openModal.type == "TREEITEM_NEW") {
      const configs = treeState.openModal.params
      if (!configs.onSave && !configs.component) {
        configs.onSave = handleDefaultCreateSave
      }

      return (
        <ErrorBoundary>
          <TreeitemNewWizardDialog
            typeTree={typeTree}
            itemTipo={configs.item_tipo}
            parentItem={configs.parent}
            configs={configs}
            onClose={closeAddWizardTreeitem}
          />
        </ErrorBoundary>
      )
    }
  }

  const openLink = (_item) => {
    return window.open(getTreeitemLinkUrl(_item, auditId), "_blank")
  }

  const onRowClickHandler = (_item, _indexes, { isOpen, from }) => {
    const handleSideModals = () => {
      switch (from) {
        case clickFrom.arrow: {
          dispatch({
            type: "TREE.TOGGLE_ROW",
            indexes: _indexes
          })

          break
        }
        case clickFrom.row: {
          if (!isOpen) {
            dispatch({
              type: "TREE.OPEN_ROW",
              indexes: _indexes
            })
          }

          const paramQuery = getParamQueryByTypeTree(typeTree)
          const auditId = router.query[`${paramQuery}`] === "" ? hardcodedAuditId : router.query[`${paramQuery}`]
          router
            .push({
              query: {
                [`${paramQuery}`]: auditId,
                "hoja-id": _item.id
              }
            })
            .finally(() => {})

          break
        }
      }
    }

    switch (getTreeitemConfig(_item, "onTreeitemClick")) {
      case "openlink":
        openLink(_item)
        break
      case "toggle":
        batch(() => {
          if (_item.isOpen) {
            dispatch({ type: "TREE.TOGGLE_ROW", indexes: _indexes })
          } else {
            dispatch({ type: "TREE.ACTIVATE_ROW", indexes: _indexes, prevIndexes: selectedIndexes })
          }
        })

        break
      case "sidemodal":
        handleSideModals()
        break
    }

    setSelectedIndexes([..._indexes])
  }

  const calcRiesgoInherente = (v) => {
    if (riSettings === null) {
      return "@LOADING"
    }

    if (v === null) {
      return null
    }

    let retval = null

    riSettings.some((range_it, k) => {
      if (typeof range_it.min !== "undefined" && range_it["min"] < v && typeof range_it["max"] === "undefined") {
        retval = range_it.value
        return range_it.value
      }

      if (typeof range_it.max !== "undefined" && range_it["max"] >= v && typeof range_it["min"] === "undefined") {
        retval = range_it.value
        return range_it.value
      }
      if (k == riSettings.length - 1) {
        if (
          typeof range_it.max !== "undefined" &&
          typeof range_it.min !== "undefined" &&
          range_it.max >= v &&
          range_it.min < v
        ) {
          retval = range_it.value
          return range_it["value"]
        }
        if (
          typeof range_it.max !== "undefined" &&
          typeof range_it.min !== "undefined" &&
          range_it.max > v &&
          range_it.min <= v
        ) {
          retval = range_it.value
          return range_it.value
        }
      } else {
        if (
          typeof range_it.max !== "undefined" &&
          typeof range_it.min !== "undefined" &&
          range_it.max > v &&
          range_it.min <= v
        ) {
          retval = range_it.value
          return range_it.value
        }
      }
    })

    return retval
  }

  useEffect(() => {
    try {
      if (worksheetId !== "") {
        const [item, indexes] = findInTreeBy(treeState.rows, (row) => row["id"] === worksheetId)
        if (item === null) {
          return
        }
        const _indexes = []
        indexes.map((el, index) => {
          if (index < indexes.length - 1) {
            _indexes.push(el)
            dispatch({
              type: "TREE.ACTIVATE_ROW",
              indexes: _indexes,
              prevIndexes: selectedIndexes
            })
          }
        })

        dispatch({ type: "TREE.OPEN_ROW", indexes })
        dispatch(
          sidenavContainerActions.openWorksheetAsync({
            data: item,
            indexes: indexes,
            key: item.type,
            auditId,
            typeTree
          })
        )
      }
    } catch (error) {
      //
    }
  }, [worksheetId])

  useEffect(() => {
    return () => {
      dispatch(sidenavContainerActions.closeWorksheetsAndNormals())
    }
  }, [])

  const isAuditProject = projectData && projectData["@type"] === "auditoria"
  useEffect(() => {
    if (isAuditProject) {
      const treecolumns = getAuditConfig("treecolumns")
      if (
        treecolumns?.includes("cumplimiento_score") &&
        typeof treeState?.configs.cstm_pasoitem_score_ranges === "undefined"
      ) {
        fetchConfig("cstm_pasoitem_score_ranges")
      }
    }
  }, [isAuditProject])

  const getAuditConfig = (config) => {
    if (!projectData) {
      return false
    }

    if (projectData["@type"] !== "auditoria") {
      return null
    }

    if (typeof projectData.configs[config] == "undefined") {
      return null
    }

    return projectData.configs[config]
  }

  function getNumTotalFindings(data) {
    let contFinding = 0
    if (typeof data !== "undefined") {
      data?.map((element) => {
        if (element.type === rowFrontendType.FINDING) {
          contFinding++
        }
        if (element.items && element.items.length > 0) {
          contFinding += getNumTotalFindings(element.items)
        }
      })
    }
    return contFinding
  }

  function getNumTotalRisks(data) {
    let countRecords = 0
    if (typeof data !== "undefined") {
      data?.map((element) => {
        if (element.type === rowFrontendType.RISK) {
          countRecords++
        }
        if (element.items && element.items.length > 0) {
          countRecords += getNumTotalRisks(element.items)
        }
      })
    }
    return countRecords
  }

  const fetchConfig = (config) => {
    dispatch({ type: "TREE.SET_CONFIG", value: { [config]: "@LOADING" } })
    getGenericSetting("auditoria_tree_" + config, currentEmpresaId).then(({ data }) => {
      dispatch({ type: "TREE.SET_CONFIG", value: { [config]: data?.valor } })
    })
  }

  let valueRisk = []
  let arrayAverageSolidityControl = []

  const calcAvgFromValueList = (values) => {
    if (values === null) {
      return null
    }

    if (values.length === 0) {
      return null
    }

    const sumArray = values.reduce((a, b) => a + b, 0)

    return sumArray / values.length
  }

  function calcAverageRiskField(data, type) {
    data.map((element) => {
      if (element.items && element.items.length > 0) {
        calcAverageRiskField(element.items, type)
      }
      if (element.type === rowFrontendType.RISK) {
        if (
          type === "riesgo_inherente" &&
          !isNaN(parseInt(element.impacto)) &&
          !isNaN(parseInt(element.probabilidad))
        ) {
          valueRisk.push(element.impacto * element.probabilidad)
        } else if (typeof element[type] !== "undefined" && element[type] !== null) {
          valueRisk.push(element[type])
        }
      }
    })

    if (valueRisk.length === 0) {
      return null
    }

    const sumArray = valueRisk.reduce((a, b) => a + b, 0)

    return sumArray / valueRisk.length
  }

  function averageSolidityControlRisk(data, _type) {
    data.map((element) => {
      if (element.items && element.items.length > 0) {
        averageSolidityControlRisk(element.items, _type)
      }
      if (element.type === rowFrontendType.RISK) {
        if (typeof element[_type] !== "undefined" && element[_type] !== null) {
          arrayAverageSolidityControl.push(element[_type])
        }
      }
    })

    if (arrayAverageSolidityControl.length === 0) {
      return null
    }

    const sumArray = arrayAverageSolidityControl.reduce((a, b) => a + b, 0)

    const avg = sumArray / arrayAverageSolidityControl.length

    if (avg < 3) {
      return 1
    }
    if (avg < 4.5) {
      return 3
    }
    if (avg >= 4.5) {
      return 5
    }

    return null
  }

  const addRiskColumnsInfo = (item, columns = ["ri", "rr"]) => {
    const averageInherentRisk = calcAverageRiskField(item.items, "riesgo_inherente")
    const averageSolidityControl = averageSolidityControlRisk(item.items, "controles_solidez")

    const ri = calcRiesgoInherente(averageInherentRisk)

    if (columns.includes("ri")) {
      item.statuses.push({
        statusId: "INHERENT_RISK",
        data: {
          value: ri,
          color: findFromConfigList(treeState?.configs.riesgo_ri_ranges, ri, "color"),
          label: findFromConfigList(treeState?.configs.riesgo_ri_ranges, ri, "name")
        }
      })
    }

    if (columns.includes("rr")) {
      let rr = null
      if (!ri || ri === "@LOADING") {
        rr = ri
      } else if (!averageSolidityControl) {
        rr = ri
      } else {
        const c = `${ri}${averageSolidityControl}`
        if (["55", "45", "35", "25", "23", "11"].includes(c)) {
          rr = 2
        } else if (["43", "33", "31"].includes(c)) {
          rr = 3
        } else if (["41", "53"].includes(c)) {
          rr = 4
        } else {
          rr = 5
        }
      }

      item.statuses.push({
        statusId: "RESIDUAL_RISK",
        data: {
          value: rr,
          color: findFromConfigList(treeState?.configs.riesgo_ri_ranges, rr, "color"),
          label: findFromConfigList(treeState?.configs.riesgo_ri_ranges, rr, "name")
        }
      })
    }

    if (columns.includes("solidez")) {
      const _result = Math.round(averageSolidityControl)
      item.statuses.push({
        statusId: "SOLIDITY_CONTROL",
        data: {
          value: averageSolidityControl !== null && _result % 2 === 0 ? _result + 1 : _result
        }
      })
    }
  }

  const collectCumplimiento = (items) => {
    const collection = []

    items?.forEach((item) => {
      if (
        item.item_tipo == "HT_PASO" &&
        item.paso_tipo === "INSPECCION_ITEM" &&
        item.parent_paso_tipo == "INSPECCION"
      ) {
        if (typeof item?.cumplimiento !== "undefined" && item?.cumplimiento !== null) {
          collection.push(item.cumplimiento)
        }
      } else if (item.items?.length > 0) {
        const c = collectCumplimiento(item.items)
        if (c.length > 0) {
          const sum = c.reduce((accum, curr) => curr + accum, 0)
          collection.push(Math.round((100 * sum) / c.length) / 100)
        }
      }
    })

    return collection
  }

  const addCumplimientoColumnsInfo = (item, auditConfigs = null) => {
    if (auditConfigs === null) {
      auditConfigs = getAuditConfig("treecolumns")
    }

    const scorelist = collectCumplimiento(item.items)

    if (
      (auditConfigs?.includes("cumplimiento_porc") || auditConfigs?.includes("cumplimiento_score")) &&
      countChildrenBy(item?.items, (el) => el.paso_tipo === "INSPECCION_ITEM" && el.parent_paso_tipo == "INSPECCION")
    ) {
      if (auditConfigs?.includes("cumplimiento_porc")) {
        const value = calcAvgFromValueList(scorelist)
        item.statuses.push({
          statusId: "custom",
          data: {
            title: t("frontend.auditoria.paso.cumplimiento_score.percent__short"),
            tooltip: t("frontend.auditoria.paso.cumplimiento_score.percent"),
            value: value === null ? value : Math.round(value) + "%"
          }
        })
      }

      if (auditConfigs?.includes("cumplimiento_score")) {
        let value
        if (scorelist === null || scorelist?.length == 0) {
          value = null
        } else {
          if (
            typeof treeState?.configs?.cstm_pasoitem_score_ranges === "undefined" ||
            treeState?.configs?.cstm_pasoitem_score_ranges === null ||
            treeState?.configs?.cstm_pasoitem_score_ranges === "@LOADING"
          ) {
            value = <div className='ui active loader tiny text'></div>
          } else {
            const score = findFieldFromRanges(
              calcAvgFromValueList(scorelist),
              treeState?.configs.cstm_pasoitem_score_ranges,
              "value",
              "max"
            )

            if (score !== null) {
              const color = findFromConfigList(treeState?.configs.cstm_pasoitem_score_ranges, score, "color")
              const label = findFromConfigList(treeState?.configs.cstm_pasoitem_score_ranges, score, "name")

              value = (
                <div>
                  <Pill color={color} text={label} />
                </div>
              )
            }
          }
        }

        item.statuses.push({
          statusId: "custom",
          data: {
            title: t("frontend.auditoria.paso.cumplimiento_score.score__short"),
            tooltip: t("frontend.auditoria.paso.cumplimiento_score.score"),
            value
          }
        })
      }
    }
  }

  /**
   * Returns signature ("firmas") info from a treeitem.
   *
   * @param {object} item - The treeitem
   *
   * @returns object
   *  object.completed {boolean}
   *  object.reviewed
   *  object.completers
   *  object.reviewers
   */
  const getSignatureColumnData = (item) => {
    return item.firmas
      ? {
          completed: item.firmas.completado.length > 0,
          reviewed: item.firmas.revisado.length > 0,
          completers: item.firmas.completado,
          reviewers: item.firmas.revisado
        }
      : undefined
  }

  /**
   * Checks whether a dragging treeitem can be dropped into an item.
   *
   * @param {object} item - The droppable item to check.
   *
   * @returns boolean - True if current dragging item can be dropped into param item.
   */
  const handleBeforeDrop = (item) => {
    if (!itemDraggingRef.current) {
      return false
    }

    if (itemDraggingRef.current.id == item.id) {
      return false
    }

    if (itemDraggingRef.current.item_tipo == item.item_tipo) {
      return false
    }

    const dndDropAllowed = getTreeitemConfig(item, "dndDropAllowed", [])
    if (dndDropAllowed.includes(itemDraggingRef.current.item_tipo)) {
      return true
    }

    return false
  }

  /**
   * The default treeitem row component.
   *
   * @component
   *
   * @param {Object} props
   * @param {} props.item - The treeitem
   *
   * @returns {JSX.Element}
   */
  const TreeRowDefault = ({ item }) => {
    const { indexes } = useContext(TreeRowContext)

    const _options = makeRowOptions(item, indexes)
    const iconLabelColor = rowLabelIconColor(item.type, item.statuses, item, treeState?.configs)
    const label = item.label ?? getRowLabel(item)
    const icon = getRowIcon(item)

    const treeRowReadOnly = readOnly ? true : !hasPermissionOnEditTree("edit", item)

    return (
      <TreeRow
        label={label}
        icon={icon}
        item={item}
        labelIconColor={iconLabelColor}
        key={item.id}
        isColorless={item.colorless}
        level={item.level}
        emphasis={item.emphasis}
        noContentPadding={item.noPadding}
        treeRowData={item}
        statuses={item.statuses}
        options={_options}
        open={item.isOpen}
        active={worksheetInFront ? worksheets[worksheetInFront].data.id === item.id : item.isActive}
        borderToNewItem={newItemId === item.id}
        draggable={!treeRowReadOnly && item.isDraggable}
        onDragStart={treeRowReadOnly ? undefined : makeOnDragStart({ indexes: [...indexes], item })}
        onDragEnd={treeRowReadOnly ? undefined : makeOnDragEnd()}
        onDragOver={() => {}}
        onDragEnter={() => {}}
        onDragLeave={() => {}}
        onClick={onRowClickHandler.bind(this, item, indexes)}
        onDrop={treeRowReadOnly ? undefined : makeOnDrop({ indexes: [...indexes], itemId: item.id })}
        beforeDrop={handleBeforeDrop}
        type={item.type}
        typeTree={typeTree}
        toggleButtonPosition='LEFT'
        readOnly={treeRowReadOnly}
        propsSigningButtons={{
          typeTree: "auditPlan",
          modelId: item?.id,
          statuses: item?.statuses,
          worksheetKey: item?.type,
          indexes: indexes
        }}
        href={getTreeitemLinkUrl(item, auditId)}
      >
        {Array.isArray(item.items) && item.items.length > 0 ? renderData(item.items, ...indexes) : null}
      </TreeRow>
    )
  }

  const TreeRowFase = ({ item }) => {
    const auditConfigs = getAuditConfig("treecolumns")

    item.statuses = []

    if (auditConfigs === null || auditConfigs?.includes("riesgo")) {
      const riskInPhase = countChildrenBy(item?.items, (el) => el.item_tipo == "HT_RIESGO")
      if (riskInPhase > 0 && item.items) {
        addRiskColumnsInfo(item)
      }
    }

    addCumplimientoColumnsInfo(item)

    item.statuses.push({
      statusId: "PROGRESS",
      data: `${Math.round(getProgress(item) * 100)}%`
    })

    return <TreeRowDefault item={item} />
  }

  const TreeRowDefaultGr = ({ item }) => {
    const auditConfigs = getAuditConfig("treecolumns")

    item.statuses = []

    if (item.items?.length > 0 && (auditConfigs === null || auditConfigs?.includes("riesgo"))) {
      addRiskColumnsInfo(item, typeTree == "auditPlan" ? ["ri", "rr"] : ["ri", "rr", "solidez"])
    }

    addCumplimientoColumnsInfo(item)

    if (typeTree == "auditPlan") {
      item.statuses.push({
        statusId: "FINDINGS",
        data: getNumTotalFindings(item.items)
      })
    } else {
      item.statuses.push({
        statusId: "RISKS",
        data: getNumTotalRisks(item.items)
      })
    }

    item.statuses.push({
      statusId: "PROGRESS",
      data: `${Math.round(getProgress(item) * 100)}%`
    })

    return <TreeRowDefault item={item} />
  }

  const renderRowItem = (_item, _idx, indexes) => {
    valueRisk = []
    arrayAverageSolidityControl = []

    const _indexes = [...indexes, _idx]

    switch (_item.type) {
      case rowFrontendType.PHASE: {
        return (
          <TreeRowContext.Provider key={_idx} value={{ indexes: _indexes }}>
            <TreeRowFase item={_item} />
          </TreeRowContext.Provider>
        )
      }

      case rowFrontendType.BUSINESS_UNIT:
      case rowFrontendType.MACROPROCESS:
      case rowFrontendType.PROCESS:
      case rowFrontendType.SUB_PROCESS: {
        return (
          <TreeRowContext.Provider key={_idx} value={{ indexes: _indexes }}>
            <TreeRowDefaultGr item={_item} />
          </TreeRowContext.Provider>
        )
      }
      case rowFrontendType.RISK: {
        _item.statuses = [
          {
            statusId: treeItemColumn.INHERENT_RISK,
            data: {
              value: _item.riesgo_inherente,
              color: findFromConfigList(treeState?.configs?.riesgo_ri_ranges, _item.riesgo_inherente, "color"),
              label: findFromConfigList(treeState?.configs?.riesgo_ri_ranges, _item.riesgo_inherente, "name")
            }
          },
          {
            statusId: treeItemColumn.RESIDUAL_RISK,
            data: {
              value: _item.riesgo_residual,
              color: findFromConfigList(treeState?.configs?.riesgo_ri_ranges, _item.riesgo_residual, "color"),
              label: findFromConfigList(treeState?.configs?.riesgo_ri_ranges, _item.riesgo_residual, "name")
            }
          },
          { statusId: treeItemColumn.FINDINGS, data: getNumTotalFindings(_item.items) },
          {
            statusId: treeItemColumn.STATUS,
            data: {
              status: _item.status,
              initials: _item.usuario_asignado?.sigla,
              name: _item.usuario_asignado?.name
            }
          },
          { statusId: treeItemColumn.SIGNATURE, data: getSignatureColumnData(_item) }
        ]

        return (
          <TreeRowContext.Provider key={_idx} value={{ indexes: _indexes }}>
            <TreeRowDefault item={_item} />
          </TreeRowContext.Provider>
        )
      }

      case rowFrontendType.CONTROL: {
        _item.statuses = [
          { statusId: treeItemColumn.DESIGN, data: _item.control_diseno },
          { statusId: treeItemColumn.EXECUTION, data: { value: getExecutionAverage(_item) } },
          { statusId: treeItemColumn.SOLIDITY, data: _item.control_solidez },
          { statusId: treeItemColumn.FINDINGS, data: getNumTotalFindings(_item.items) },
          {
            statusId: treeItemColumn.STATUS,
            data: {
              status: _item.status,
              initials: _item.usuario_asignado?.sigla,
              name: _item.usuario_asignado?.name
            }
          },
          { statusId: treeItemColumn.SIGNATURE, data: getSignatureColumnData(_item) }
        ]

        return (
          <TreeRowContext.Provider key={_idx} value={{ indexes: _indexes }}>
            <TreeRowDefault item={_item} />
          </TreeRowContext.Provider>
        )
      }

      case rowFrontendType.TEST: {
        const getIsDefeated = () => {
          if (_item.status == "COMPLETADO" || _item.status == "REVISADO") {
            return false
          }

          const compareDate = _item.fecha_fin ? _item.fecha_fin.split("-") : []

          return (
            new Date().setHours(0, 0, 0, 0) >
            new Date(compareDate[0], compareDate[1] - 1, compareDate[2]).setHours(0, 0, 0, 0)
          )
        }

        _item.statuses = [
          { statusId: treeItemColumn.DEFEATED, data: { status: _item.status, isDefeated: getIsDefeated() } },
          { statusId: treeItemColumn.EXECUTION, data: { value: _item.validacion_ejecucion } },
          { statusId: treeItemColumn.FINDINGS, data: getNumTotalFindings(_item.items) },
          {
            statusId: treeItemColumn.STATUS,
            data: {
              status: _item.status,
              initials: _item.usuario_asignado?.sigla,
              name: _item.usuario_asignado?.name
            }
          },
          { statusId: treeItemColumn.SIGNATURE, data: getSignatureColumnData(_item) }
        ]

        return (
          <TreeRowContext.Provider key={_idx} value={{ indexes: _indexes }}>
            <TreeRowDefault item={_item} />
          </TreeRowContext.Provider>
        )
      }

      case rowFrontendType.UNDERSTANDING: {
        _item.statuses = [
          { statusId: treeItemColumn.FINDINGS, data: getNumTotalFindings(_item.items) },
          {
            statusId: treeItemColumn.STATUS,
            data: {
              status: _item.status,
              initials: _item.usuario_asignado?.sigla,
              name: _item.usuario_asignado?.name
            }
          },
          { statusId: treeItemColumn.SIGNATURE, data: getSignatureColumnData(_item) }
        ]

        return (
          <TreeRowContext.Provider key={_idx} value={{ indexes: _indexes }}>
            <TreeRowDefault item={_item} />
          </TreeRowContext.Provider>
        )
      }

      case rowFrontendType.STEP: {
        _item.statuses = [
          {
            statusId: treeItemColumn.STATUS,
            data: {
              status: _item.status,
              initials: _item.usuario_asignado?.sigla,
              name: _item.usuario_asignado?.name
            }
          },
          { statusId: treeItemColumn.SIGNATURE, data: getSignatureColumnData(_item) }
        ]

        if (_item.paso_tipo === "INSPECCION_ITEM" && _item.parent_paso_tipo == "INSPECCION") {
          _item.statuses.unshift({
            statusId: "KPI",
            data: {
              label: "Cumplimiento",
              value: _item.cumplimiento === null ? "-" : `${_item.cumplimiento}%`
            }
          })
        }

        return (
          <TreeRowContext.Provider key={_idx} value={{ indexes: _indexes }}>
            <TreeRowDefault item={_item} />
          </TreeRowContext.Provider>
        )
      }
      case rowFrontendType.INSPECTION: {
        _item.statuses = []
        addCumplimientoColumnsInfo(_item, ["cumplimiento_porc", "cumplimiento_score"])
        _item.statuses = _item.statuses.concat([
          { statusId: treeItemColumn.FINDINGS, data: getNumTotalFindings(_item.items) },
          {
            statusId: treeItemColumn.PROGRESS,
            data: `${Math.round(getProgress(_item) * 100)}%`
          }
        ])

        return (
          <TreeRowContext.Provider key={_idx} value={{ indexes: _indexes }}>
            <TreeRowDefault item={_item} />
          </TreeRowContext.Provider>
        )
      }

      case rowFrontendType.FINDING: {
        _item.statuses = [
          { statusId: treeItemColumn.TYPE, data: { value: _item.tipo_hallazgo } },
          { statusId: treeItemColumn.LOST, data: { value: _item.perdida_cuantificada } },
          { statusId: treeItemColumn.IMPACT, data: { value: _item.impacto } },
          {
            statusId: treeItemColumn.STATUS,
            data: {
              status: _item.status,
              initials: _item.usuario_asignado?.sigla,
              name: _item.usuario_asignado?.name
            }
          },
          { statusId: treeItemColumn.SIGNATURE, data: getSignatureColumnData(_item) }
        ]

        return (
          <TreeRowContext.Provider key={_idx} value={{ indexes: _indexes }}>
            <TreeRowDefault item={_item} />
          </TreeRowContext.Provider>
        )
      }

      case rowFrontendType.ACTION_PLAN: {
        _item.statuses = [
          { statusId: "EXPIRATION" },
          { statusId: "RESPONSABLE" },
          { statusId: "ACTION_PLAN", data: (item) => ({ status: item.plan_accion_status }) }
        ]

        return (
          <TreeRowContext.Provider key={_idx} value={{ indexes: _indexes }}>
            <TreeRowDefault item={_item} />
          </TreeRowContext.Provider>
        )
      }

      case rowFrontendType.EVALUATION: {
        _item.statuses = [
          { statusId: "custom", data: { title: "Cumplimiento", value: `${_item.plan_accion_nivel_cumplimiento}%` } },
          { statusId: "EVALUATION_STATE", data: { status: _item.status_evaluacion } },
          {
            statusId: treeItemColumn.SIGNATURE,
            data: _item.evaluacion_firmas
              ? {
                  completed: _item.evaluacion_firmas.completado.length > 0,
                  reviewed: _item.evaluacion_firmas.revisado.length > 0,
                  completers: _item.evaluacion_firmas.completado,
                  reviewers: _item.evaluacion_firmas.revisado
                }
              : undefined
          }
        ]

        return (
          <TreeRowContext.Provider key={_idx} value={{ indexes: _indexes }}>
            <TreeRowDefault item={_item} />
          </TreeRowContext.Provider>
        )
      }

      case rowFrontendType.SURVEY: {
        _item.statuses = [
          {
            statusId: treeItemColumn.STATUS,
            data: {
              status: _item.status,
              initials: _item.usuario_asignado?.sigla,
              name: _item.usuario_asignado?.name
            }
          },
          { statusId: treeItemColumn.SIGNATURE, data: getSignatureColumnData(_item) }
        ]

        return (
          <TreeRowContext.Provider key={_idx} value={{ indexes: _indexes }}>
            <TreeRowDefault item={_item} />
          </TreeRowContext.Provider>
        )
      }

      default:
        return (
          <TreeRowContext.Provider key={_idx} value={{ indexes: _indexes }}>
            <TreeRowDefault item={_item} />
          </TreeRowContext.Provider>
        )
    }
  }

  const renderData = (_data, ...indexes) =>
    sortTreeData(_data, viewMode == "list").map((_item, idx) => {
      return renderRowItem(_item, idx, indexes)
    })

  const getInitialSimplifiedData = (_data) => {
    const simplifiedData = cloneDeep(_data)

    const canAdd = (_item) => {
      switch (_item.type) {
        case rowFrontendType.PHASE:
        case rowFrontendType.BUSINESS_UNIT:
        case rowFrontendType.MACROPROCESS:
        case rowFrontendType.PROCESS:
        case rowFrontendType.SUB_PROCESS:
        case rowFrontendType.ACTION_PLAN:
        case rowFrontendType.EVALUATION:
          return false
      }
      return true
    }

    const simplifiedArray = []

    const traverseData = (items, ...indexes) => {
      items.forEach((_item, _idx) => {
        const ok = canAdd(_item)

        let _indexes = [...indexes, _idx]

        if (ok) {
          let cleanedItem = cloneDeep(_item)

          cleanedItem.items = []
          cleanedItem.indexes = _indexes

          simplifiedArray.push(cleanedItem)
        }

        if (!_item.items || _item.items.length === 0) {
          return
        }

        traverseData(_item.items, ..._indexes)
      })
    }

    traverseData(simplifiedData)

    return simplifiedArray
  }

  const filterRowsByFilters = (rows) => {
    return rows.filter((item) => {
      if (searchText && !matchsSearchText(searchText, item, ["codigo", "nombre"])) {
        return false
      }

      if (filtersApplied?.usuario_asignado) {
        if (filtersApplied?.usuario_asignado == "sin_asignar" && item.usuario_asignado) {
          return false
        } else if (
          filtersApplied?.usuario_asignado != "sin_asignar" &&
          item.usuario_asignado?.id != filtersApplied.usuario_asignado
        ) {
          return false
        }
      }

      if (filtersApplied?.status && item.status != filtersApplied.status) {
        return false
      }

      if (filtersApplied?.item_tipo && getTreeitemType(item) != filtersApplied.item_tipo) {
        return false
      }

      return true
    })
  }

  const renderSprintSelector = () => {
    return (
      <div style={{ display: "inline-flex", justifyContent: "center" }}>
        <FormField label={t("frontend.auditoria.sprint.__name__")} positionLabel='left'>
          <Select
            selectionChange={sprints.onChange}
            initialOptions={sprints?.items?.map((x) => {
              return typeof sprints.selected === "undefined"
                ? { id: x.id, label: x.nombre, selected: x.is_current }
                : { id: x.id, label: x.nombre, selected: x.id == sprints.selected }
            })}
          />
        </FormField>
      </div>
    )
  }

  const renderImportRisks = () => {
    const TreeInitialWizard = dynamic(() => import("../AuditTree/TreeInitialWizard"), {
      loading: () => <Loading centered={false} />
    })

    const handleSubmit = ({ type, value }) => {
      switch (type) {
        case "plantilla":
          dispatch({
            type: "PAGE_LOADING",
            value: {
              text: (
                <>
                  Creando desde Plantilla, <br /> Por favor Espere...
                </>
              ),
              type: "HashLoader"
            }
          })

          createFromTemplate(typeTree === "auditPlan" ? "auditoria" : "evaluacion", auditId, value)
            .then(() => {
              location.reload()
            })
            .catch(() => {
              dispatch({ type: "PAGE_LOADING", value: false })
            })

          break

        case "treeitem_new":
          if (typeTree == "auditPlan") {
            dispatch({ type: "TREE.OPEN_MODAL", value: "TREEITEM_NEW", params: { item_tipo: "GR_FASE", parent: null } })
          } else {
            dispatch({
              type: "TREE.OPEN_MODAL",
              value: "TREEITEM_NEW",
              params: { item_tipo: "GR_UNIDAD_NEGOCIO", parent: null }
            })
          }
      }
    }

    const renderEmptyText = () => {
      if (typeTree == "auditPlan") {
        if (sprints?.enabled) {
          return "frontend.auditoria.sprint.message_empty_tree"
        }
        return "frontend.auditoria.plan.message_empty_tree"
      } else {
        return "frontend.evaluacion.message_empty_tree"
      }
    }

    return (
      <>
        <div className={styles.NoDataWrapper}>
          <div className={cx(styles.sprintWrapper)}>{sprints?.enabled && renderSprintSelector()}</div>
          <div className={styles.Alert}>
            <NoData style={{ fontSize: 15, color: "#985a00", textAlign: "center" }} text={t(renderEmptyText())} />
          </div>
          {(typeTree === "auditPlan"
            ? hasAuditMutablePermission("plan.risk__import")
            : hasPermission("pyr_evaluacion.edit_tree")) && (
            <div style={{ maxWidth: 900, margin: "auto" }}>
              <TreeInitialWizard
                typeTree={typeTree === "auditPlan" ? "auditoria" : "evaluacion"}
                onSubmit={handleSubmit}
              />
            </div>
          )}
        </div>
      </>
    )
  }

  const hasFilters = searchText || Object.keys(filtersApplied)?.length > 0

  const getViewMode = () => {
    if (viewMode != "list") {
      if (hasFilters) {
        return "list"
      }

      return viewMode
    } else {
      return viewMode
    }
  }
  const currViewMode = getViewMode()

  const renderView = () => {
    switch (currViewMode) {
      case "tree":
        return treeState.rows?.length > 0 ? (
          <TreeConfigContext.Provider value={{ treeConfigs: treeState.configs }}>
            <ErrorBoundary>{renderData(treeState.rows)}</ErrorBoundary>
          </TreeConfigContext.Provider>
        ) : (
          renderImportRisks()
        )
      case "list":
        if (treeState.rows?.length > 0) {
          const rows = getInitialSimplifiedData(treeState.rows)

          const filteredRows =
            searchText || Object.keys(filtersApplied)?.length > 0 ? filterRowsByFilters(rows) : [...rows]

          return (
            <TreeConfigContext.Provider value={{ treeConfigs: treeState.configs }}>
              {filteredRows.map((_item, _idx) => {
                const _indexes = _item.indexes

                let label = getRowLabel(_item)
                if (_item.item_tipo == "HT_PASO" && _item.paso_tipo == "INSPECCION_ITEM") {
                  const parent = rows.find((x) => x.id == _item.parentId)
                  label = `[${getRowLabel(parent)}] > ${label}`
                }

                return renderRowItem({ ..._item, label }, _idx, _indexes)
              })}
            </TreeConfigContext.Provider>
          )
        } else {
          return renderImportRisks()
        }
    }
  }

  return (
    <>
      <div className={`${styles.treeWrapper}${className ? " " + className : ""}`} draggable={false} {...otherProps}>
        <div className={styles.treeMenu}>
          <div className={styles.titleMenu}>{auditName}</div>
          {treeState.rows?.length > 0 && (
            <div className={styles.treeTopbar}>
              {viewMode == "tree" ? (
                <ExpandAllBox
                  onClickExpandAll={(expand) => dispatch({ type: expand ? "TREE.EXPAND" : "TREE.COLLAPSE" })}
                />
              ) : (
                <div>&nbsp;</div>
              )}

              <div className={styles.sprintWrapper}>{sprints?.enabled && renderSprintSelector()}</div>

              <div className={styles.sideOptions}>
                <div className={styles.sideOption}>
                  <InputSearch onValueChange={onSearch} initialValue={searchText} />
                </div>

                <div>{AdvancedFiltersButton({ className: "tiny" })}</div>

                <div className={styles.sideOption}>
                  <div className='ui icon very compact buttons'>
                    <button
                      className={cx("ui button", currViewMode == "tree" && "active")}
                      onClick={() => !hasFilters && setViewMode("tree")}
                      title={t("main_ui.general.layout_view_tree")}
                    >
                      <Icon name='view_tree' />
                    </button>
                    <button
                      className={cx("ui button", currViewMode == "list" && "active")}
                      onClick={() => setViewMode("list")}
                      title={t("main_ui.general.layout_view_list_simplified")}
                    >
                      <Icon name='view_list' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div>{AdvancedFiltersPanel()}</div>
        {searchText || Object.keys(filtersApplied)?.length > 0 ? (
          <div style={{ width: "100%", paddingBottom: 5 }}>
            <FilterWarning
              onRemoveFilter={() => {
                setSearchText("")
                removeFilters()
              }}
            />
          </div>
        ) : null}

        <div>{renderView()}</div>
        {!readOnly && renderDialogs()}
      </div>
    </>
  )
}
